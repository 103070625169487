<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-xl-12"> <b-form-group id="input-group-2"
                                label="ชื่อหน่วยงาน (Th)" label-for="input-2">
                                <b-form-input id="input-2" v-model="nameTh" placeholder="กรอกชื่อหน่วยงานภาษาไทย"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-12 col-xl-12"> <b-form-group id="input-group-2"
                                label="ชื่อหน่วยงาน (Eng)" label-for="input-2">
                                <b-form-input id="input-2" v-model="nameEng" placeholder="ชื่อหน่วยงานภาษาอังกฤษ"
                                    required></b-form-input>
                            </b-form-group></div>
                    </div>
                    <div class="submit-container">
                        <b-button variant="outline-black-100" v-on:click=(updateInstitution())>บันทึก</b-button>
                    </div>
                </b-form>


            </b-card>
        </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormSelect, BFormInput, BAlert, BSpinner, BModal, BBreadcrumb } from "bootstrap-vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import institutionService from "../../../../../services/institution.service";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        PageTitle
    },
    data() {
        return {
            institutionId: null,
            nameTh: null,
            nameEng: null,
            show: true,
            isLoading: false,
            errors: [],
            breadcrumbItems: [
                {
                    text: "การชำระเงิน",
                    href: "#",
                },
                {
                    text: "หน่วยงาน",
                    to: { name: "institutions" },
                },
                {
                    text: "แก้ไขหน่วยงาน",
                    active: true,
                },
            ],
        };
    },

    methods: {
        getInstitutions: async function () {
            this.institutionId = this.$route.params.id;
            let response = await institutionService.getInstitutionById(this.institutionId);
            let data = response.data.institution;
            this.nameTh = data.name_th;
            this.nameEng = data.name_eng;

        },

        updateInstitution: async function () {
            try {
                this.isLoading = true;
                if (this.isValidate()) {
                    let newR = {
                        id: this.institutionId,
                        name_th: this.nameTh,
                        name_eng : this.nameEng
                    }
                    await institutionService.updateInstitution(newR);
                    this.isLoading = false;
                    setTimeout(() => { this.isSaved = false; }, 3000)
                } else {
                    this.isLoading = false;
                    this.$bvModal
                        .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                            cancelVariant: "text",
                            okTitle: "ตกลง",
                            size: "sm",
                        })
                        .then((value) => {
                            this.boxOne = value;
                        })
                }
            } catch (error) {
                this.$bvModal
                    .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }


        },
        isValidate() {
            this.errors = [];
            if (!this.nameEng) this.errors.push("Name English required.");
            if (!this.nameTh) this.errors.push("Name Thai required.");

            if (!this.errors.length) {
                return true;
            }
            return false;
        },

    },
    mounted() {
        this.getInstitutions();
    }

};
</script>