import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class InstitutionService {
    async getInstitutions(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : ""; 
        let institutions = await axiosService.get(CONSTANTS.APIURL + `institutions-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return institutions;
    }
    async getInstitutionById(id) {
        let institutions = await axiosService.get(CONSTANTS.APIURL + `institution?id=${id}` );
        return institutions;
    }
    async addInstitution(...institutions) {
        return await axiosService.post(CONSTANTS.APIURL + 'institutions', ...institutions);
    }
    async updateInstitution(...institutions) {
        return await axiosService.put(CONSTANTS.APIURL +  'institutions', ...institutions);
    }
    async deleteInstitution(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`institutions?id=${id}`);
    }
    async getInstitution() {
        return await axiosService.get(CONSTANTS.APIURL +`institutions`);
    }
}

export default new InstitutionService();